// extracted by mini-css-extract-plugin
export var centerSpaceBetween = "Header-module--centerSpaceBetween--5d473";
export var dropdownBtn = "Header-module--dropdownBtn--a0db2";
export var header = "Header-module--header--357cc";
export var headerGrid = "Header-module--headerGrid--777f0";
export var icon = "Header-module--icon--0bac0";
export var menuGridItem = "Header-module--menuGridItem--34c0e";
export var sideBar = "Header-module--sideBar--edca7";
export var sideBarDd = "Header-module--sideBar-dd--baeab";
export var socials = "Header-module--socials--d47df";
export var toggled = "Header-module--toggled--b04da";