import React from 'react'
import * as styles from './Footer.module.css'

const Footer: React.FunctionComponent = () => {
  const flameGif =
    'https://media2.giphy.com/media/v1.Y2lkPTc5MGI3NjExMTNmNzQ2MDRlZTNjMWUwMTU4ZGIwMDliNmE5NDdmNDI4YWZiZDdlOSZjdD1z/SVHO60sBL6aMDIEIN0/giphy.gif'

  return (
    <div className={styles.footer}>
      <div>
        <img
          height={20}
          width={20}
          src={flameGif}
          alt="Animated GIF of a flame"
        />
        forged in the fire
        <img
          height={20}
          width={20}
          src={flameGif}
          alt="Animated GIF of a flame"
        />
      </div>
      <div>by anna mola © {`${new Date().getFullYear()}`}</div>
    </div>
  )
}

export default Footer
