import React from 'react'
import Header from '../components/Header/Header'
import HeaderOffset from '../components/Header/HeaderOffset'
import Footer from '../components/Footer/Footer'
import { Stack } from '@mui/material'
import { motion } from 'framer-motion'

export default function Layout({ children }: any) {
  return (
    <Stack minHeight="100vh" justifyContent="space-between">
      <Stack>
        <Header />
        <HeaderOffset />
        <motion.main
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            type: 'spring',
            mass: 0.35,
            stiffness: 75,
            duration: 0.3,
          }}
        >
          {children}
        </motion.main>
      </Stack>
      <Footer />
    </Stack>
  )
}
