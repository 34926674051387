module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":"src/config/createEmotionCache"},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"web":[{"name":"Roboto Slab","file":"https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"GatsbyJS","short_name":"GatsbyJS","start_url":"/","background_color":"#6b37bf","theme_color":"#6b37bf","display":"standalone","icons":[{"src":"src/images/favicons/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"src/images/favicons/android-chrome-512x512.png","sizes":"512x512","type":"image/png"},{"src":"src/images/favicons/apple-touch-icon.png","sizes":"180x180","type":"image/png"},{"src":"src/images/favicons/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"src/images/favicons/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"src/images/favicons/favicon.ico","sizes":"16x16","type":"image/x-icon"}],"icon":"src/images/favicons/favicon-32x32.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"d317eee1cf4f5e62680bac21aef6b199"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
