import { Box, Grid, Button, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import HeaderOffset from './HeaderOffset'
import AnimatedLogo from '../AnimatedLogo/AnimatedLogo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Turn as Hamburger } from 'hamburger-react'
import * as styles from './Header.module.scss'
import {
  faGithubSquare,
  faLinkedin,
  faInstagramSquare,
} from '@fortawesome/free-brands-svg-icons'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { COLORS } from '../../config/theme'

const Header: React.FunctionComponent = () => {
  const [isOpen, setOpen] = React.useState(false)
  const [isSideBarDropdownOpen, setSideBarDropdownOpen] = React.useState(false)
  const [isOverButton, setOverButton] = React.useState(false)
  const [isOverList, setOverList] = React.useState(false)
  const [listOpen, setListOpen] = React.useState(false)

  useEffect(() => {
    if (listOpen && !isOverButton && !isOverList) {
      setListOpen(false)
    } else if (!listOpen && (isOverButton || isOverList)) {
      setListOpen(true)
    }
  }, [isOverButton, isOverList, listOpen])

  const dropdownOpts = ['art', 'zine', 'web design', 'resume', 'this website']
  return (
    <Box component="header" className={styles.header}>
      <Grid container className={styles.headerGrid}>
        <Grid item xs={9} sm={10} md={3}>
          <a href="/">
            <AnimatedLogo />
          </a>
        </Grid>
        <Box sx={{ display: { xs: 'none', md: 'contents' } }}>
          <Grid className={styles.menuGridItem} item sm={1} md={2}>
            <a href="/about">
              <Typography variant="h4">about</Typography>
            </a>
          </Grid>
          <Grid className={styles.menuGridItem} item sm={1} md={2}>
            <a
              href={''}
              onMouseEnter={() => {
                setOverButton(true)
              }}
              onMouseLeave={() => {
                setOverButton(false)
              }}
            >
              <div className={styles.centerSpaceBetween}>
                <Typography variant="h4">portfolio</Typography>
                <FontAwesomeIcon icon={faCaretDown} style={{ marginLeft: 5 }} />
              </div>
            </a>
            {listOpen ? (
              <div
                onMouseEnter={() => {
                  setOverList(true)
                }}
                onMouseLeave={() => {
                  setOverList(false)
                }}
              >
                {dropdownOpts.map((title, i) => (
                  <a
                    key={'dropdown_' + title}
                    href={
                      '/portfolio/' + title.replace(/\s+/g, '-').toLowerCase()
                    }
                  >
                    <Button
                      className={styles.dropdownBtn}
                      sx={{
                        top: 60 + i * 38,
                        borderBottomWidth:
                          i !== dropdownOpts.length - 1 ? 0 : 2,
                        borderTopLeftRadius: i === 0 ? 5 : 0,
                        borderTopRightRadius: i === 0 ? 5 : 0,
                        borderBottomLeftRadius:
                          i === dropdownOpts.length - 1 ? 5 : 0,
                        borderBottomRightRadius:
                          i === dropdownOpts.length - 1 ? 5 : 0,
                      }}
                    >
                      <Typography
                        variant="h4"
                        style={{
                          textTransform: 'lowercase',
                          color: COLORS.black,
                        }}
                      >
                        {title}
                      </Typography>
                    </Button>
                  </a>
                ))}
              </div>
            ) : null}
          </Grid>
          <Grid className={styles.menuGridItem} item sm={1} md={2}>
            <a href="/contact">
              <Typography variant="h4">contact</Typography>
            </a>
          </Grid>
        </Box>
        <Grid
          item
          xs={3}
          sm={2}
          md={3}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <Hamburger
              rounded
              toggled={isOpen}
              toggle={setOpen}
              label="Show menu"
            />
            <div
              className={`${isOpen ? `${styles.toggled} ` : ''} ${
                styles.sideBar
              }`}
            >
              <div>
                <HeaderOffset />
                <a href="/about">
                  <Button>
                    <Typography variant="h4">about</Typography>
                  </Button>
                </a>
                <div className="side-bar-dd">
                  <Button
                    onClick={() => {
                      setSideBarDropdownOpen(!isSideBarDropdownOpen)
                    }}
                    style={{
                      backgroundColor: isSideBarDropdownOpen
                        ? 'rgb(20, 120, 74, 0.2)'
                        : 'transparrent',
                    }}
                  >
                    <div className={styles.centerSpaceBetween}>
                      <Typography variant="h4">portfolio</Typography>
                      <FontAwesomeIcon
                        icon={faCaretDown}
                        style={{ marginRight: 20 }}
                      />
                    </div>
                  </Button>
                </div>
                {isSideBarDropdownOpen
                  ? dropdownOpts.map((title) => (
                      <a
                        key={'sidebar_dropdown_' + title}
                        href={
                          '/portfolio/' +
                          title.replace(/\s+/g, '-').toLowerCase()
                        }
                      >
                        <Button
                          sx={{
                            paddingLeft: 10,
                            backgroundColor: 'rgb(20, 120, 74, 0.1)',
                          }}
                        >
                          <Typography variant="h4">{title}</Typography>
                        </Button>
                      </a>
                    ))
                  : null}
                <a href="/contact">
                  <Button>
                    <Typography variant="h4">contact</Typography>
                  </Button>
                </a>
              </div>
              <div className={styles.socials}>
                <a href="https://www.instagram.com/bythemol/">
                  <FontAwesomeIcon
                    icon={faInstagramSquare}
                    className={styles.icon}
                  />
                </a>
                <a href="https://www.linkedin.com/in/anna-mola-b90581104/">
                  <FontAwesomeIcon icon={faLinkedin} className={styles.icon} />
                </a>
                <a href="https://github.com/annamola/">
                  <FontAwesomeIcon
                    icon={faGithubSquare}
                    className={styles.icon}
                  />
                </a>
              </div>
            </div>
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <div className={styles.socials}>
              <a href="https://www.instagram.com/bythemol/">
                <FontAwesomeIcon
                  icon={faInstagramSquare}
                  className={styles.icon}
                />
              </a>
              <a href="https://www.linkedin.com/in/anna-mola-b90581104/">
                <FontAwesomeIcon icon={faLinkedin} className={styles.icon} />
              </a>
              <a href="https://github.com/annamola/">
                <FontAwesomeIcon
                  icon={faGithubSquare}
                  className={styles.icon}
                />
              </a>
            </div>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Header
