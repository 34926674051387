exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lenny-tsx": () => import("./../../../src/pages/lenny.tsx" /* webpackChunkName: "component---src-pages-lenny-tsx" */),
  "component---src-pages-portfolio-art-tsx": () => import("./../../../src/pages/portfolio/art.tsx" /* webpackChunkName: "component---src-pages-portfolio-art-tsx" */),
  "component---src-pages-portfolio-resume-tsx": () => import("./../../../src/pages/portfolio/resume.tsx" /* webpackChunkName: "component---src-pages-portfolio-resume-tsx" */),
  "component---src-pages-portfolio-this-website-tsx": () => import("./../../../src/pages/portfolio/this-website.tsx" /* webpackChunkName: "component---src-pages-portfolio-this-website-tsx" */),
  "component---src-pages-portfolio-web-design-tsx": () => import("./../../../src/pages/portfolio/web-design.tsx" /* webpackChunkName: "component---src-pages-portfolio-web-design-tsx" */),
  "component---src-pages-portfolio-zine-tsx": () => import("./../../../src/pages/portfolio/zine.tsx" /* webpackChunkName: "component---src-pages-portfolio-zine-tsx" */)
}

